import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import { graphql, Link } from "gatsby"

import Controls from "@src/components/Controls"
import { LoadingContext } from "@src/contexts/LoadingContext"
import styled from "@emotion/styled"
import { Transition } from "react-transition-group"
import { MediaContext } from "@src/contexts/MediaContext"
import { navigate } from "../../.cache/gatsby-browser-entry"

// Transition
const LexiqueTransition = {
  entering: {
    opacity: 0,
  },
  entered: {
    opacity: 1,
    transition: `opacity 80ms ease-in`,
  },
  exiting: {
    transition: `opacity 80ms ease-out`,
    opacity: 0,
  },
  exited: {
    display: "none",
  },
}

const Wrapper = styled.div`
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  flex-wrap: wrap;
  padding: 30px;
  overflow: auto;
  font-family: "Fight Night";
`

const LexiqueControls = styled(Controls)`
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
`

const LexiqueVideoWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #2d2c2d;
  pointer-events: none;
  z-index: 1;

  video {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    object-fit: cover;
    background-color: #2d2c2d;
  }
`

const Column = styled.div`
  width: 16.66666%;

  @media (max-width: ${props => props.theme.breakpoints.large}) {
    width: 33.33333%;
  }

  @media (max-width: ${props => props.theme.breakpoints.medium}) {
    width: 50%;
  }

  @media (max-width: ${props => props.theme.breakpoints.small}) {
    width: 100%;
  }
`

const Item = styled.button`
  display: block;
  width: 100%;
  background: transparent;
  border: none;
  outline: none;
  color: #f91530;
  font-size: ${props => props.theme.fontSizes.lexique};
  font-weight: ${props => props.theme.fontWeights.bold};
  margin: 4px 0;
  text-overflow: ellipsis;
  text-align: left;
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap;
  letter-spacing: 1px;
  transition: all 150ms ease-out;

  color: #f91530;
  text-decoration: none;

  @media (min-width: ${props => props.theme.breakpoints.large}) {
    font-size: ${props => props.theme.fontSizes.lexiquelarge};
  }

  @media (max-width: ${props => props.theme.breakpoints.small}) {
    text-align: center;
  }

  & > span {
    color: black;
    z-index: -1;
    transform: translate(6px, 6px);
  }

  &.withClick {
    cursor: pointer;
  }

  .touchevents &,
  .no-touchevents &.withClick:hover {
    text-shadow: 3px 2px black;
    margin-left: 20px;
  }

  &:focus,
  &:hover {
    color: #f91530;
  }

  &.isWhite {
    color: white;
    text-decoration: none;
    &:focus,
    &:hover {
      color: white;
    }
  }
`

const LexiquePage = ({ data, location }) => {
  const { stopLoading, startLoading } = useContext(LoadingContext)
  const [prevSlug] = useState(location.state && location.state.slug)
  const [isTransition, setIsTransition] = useState(false)
  const [display, setDisplay] = useState(false)
  const [index, setIndex] = useState([0, -1])
  const videoRef = useRef()
  const audioRef = useRef()
  const { muted, mute, unmute, playSample, playAmbiant } = useContext(
    MediaContext
  )

  const onEnded = useCallback(() => {
    setDisplay(false)
  }, [])

  const onPlay = useCallback((f, i, j) => {
    const v = videoRef.current

    setIndex([i, j])

    v.poster = f.poster.publicURL

    if (Modernizr.video && Modernizr.video.webm) {
      v.setAttribute("src", f.webm.publicURL)
    } else if (Modernizr.video && Modernizr.video.h264) {
      v.setAttribute("src", f.mp4.publicURL)
    }

    if (f.mp3) {
      setTimeout(() => playSample(f.mp3.publicURL), 1200)
    }

    v.load()
  }, [])

  const muteSound = useCallback(() => {
    if (muted) unmute()
    else mute()
  }, [muted, mute, unmute])

  const goHome = useCallback(() => {
    startLoading()
    navigate(`/${prevSlug ? prevSlug : ""}`, {
      state: { slug: prevSlug ? prevSlug : "" },
    })
  }, [startLoading, prevSlug])

  const nextSample = useCallback(() => {
    let nextSample = index[1] + 1
    let nextPart = index[0]

    if (data.lexiques.nodes[nextPart].frontmatter.items.length <= nextSample) {
      nextPart += 1
      if (data.lexiques.nodes.length <= nextPart) nextPart = 0
      nextSample = 0
    }

    onPlay(
      data.lexiques.nodes[nextPart].frontmatter.items[nextSample],
      nextPart,
      nextSample
    )
  }, [data, index, setIndex, onPlay])

  const previousSample = useCallback(() => {
    let nextSample = index[1] - 1
    let nextPart = index[0]

    if (0 > nextSample) {
      nextPart -= 1
      if (0 > nextPart) nextPart = data.lexiques.nodes.length - 1
      nextSample = data.lexiques.nodes[nextPart].frontmatter.items.length - 1
    }

    onPlay(
      data.lexiques.nodes[nextPart].frontmatter.items[nextSample],
      nextPart,
      nextSample
    )
  }, [data, index, setIndex, onPlay])

  useEffect(() => {
    const preloadImage = src =>
      new Promise(r => {
        const image = new Image()
        image.onload = r
        image.onerror = r
        image.src = src
      })

    const posters = []

    data.lexiques.nodes.forEach((n, i) =>
      n.frontmatter.items.forEach(f => posters.push(f.poster))
    )

    /*let count = 0
    data.lexiques.nodes.forEach((n, i) =>
      n.frontmatter.items.forEach(f => {
        if (f.mp3) count += 1
        else console.log(f.title)
      })
    )
    console.log(count)*/

    playAmbiant()

    Promise.all(posters.map(p => preloadImage(p))).then(() => stopLoading())
  }, [stopLoading, playAmbiant])

  return (
    <Wrapper>
      {data.lexiques.nodes.map((n, i) => (
        <Column key={i}>
          {n.frontmatter.items.map((f, j) => {
            let sample = [
              <Item
                key={`${i}-${j}`}
                onClick={() => onPlay(f, i, j)}
                className={`withClick ${f.white && "isWhite"}`}
              >
                {f.title}
              </Item>,
            ]

            if (f.space) sample.push(<Item key={`${i}-${j}-space`}>--</Item>)

            return sample
          })}
          {i === data.lexiques.nodes.length - 1 && [
            <Item title="MARGOT CHARBONNIER - CREATIVE DIRECTION">
              MARGOT CHARBONNIER - CREATIVE DIRECTION
            </Item>,
            <Item title="DEUBIETA. SARA DEUBIETA - SHOES">
              DEUBIETA. SARA DEUBIETA - SHOES
            </Item>,
            <Item title="YCCIJ - FRANZISKA VOGT - JEWELLERY">
              YCCIJ - FRANZISKA VOGT - JEWELLERY
            </Item>,
            <Item title="ANDERS BIGUM. DANIEL SGAN COHEN - VIDEO">
              ANDERS BIGUM. DANIEL SGAN COHEN - VIDEO
            </Item>,
            <Item title="MARGOT CHARBONNIER . DANIEL SGAN COHEN - 3D">
              MARGOT CHARBONNIER . DANIEL SGAN COHEN - 3D
            </Item>,
            <Item title="ZOE PHILINE - SHOOTING ASSISTANT">
              ZOE PHILIPE - SHOOTING ASSISTANT
            </Item>,
            <Item title="GUILHEM BARBOUX - WEB">GUILHEM BARBOUX - WEB</Item>,
            <Item title="PABLO CHARBONNIER - SOUND">
              PABLO CHARBONNIER - SOUND
            </Item>,
            <Item title="NATALIA VERMEER - BEAUTY">
              NATALIA VERMEER - BEAUTY
            </Item>,
            <Item title="SAMIRA LORENE - TWO MGMT - MODEL">
              SAMIRA LORENE - TWO MGMT - MODEL
            </Item>,
            <Item title="ANNA PTA - MARKETING">ANNA PTA - MARKETING</Item>,
            <Item>--</Item>,
            <Item title="SAMPLE-CM.COM" className="isWhite">
              SAMPLE-CM.COM
            </Item>,
          ]}
        </Column>
      ))}
      <Transition
        in={display}
        timeout={100}
        onEntering={() => setIsTransition(true)}
        onExited={() => setIsTransition(false)}
      >
        {state => (
          <LexiqueVideoWrapper style={LexiqueTransition[state]}>
            <video
              playsInline={true}
              ref={videoRef}
              muted={true}
              autoPlay={true}
              onLoadedData={() => setDisplay(true)}
              onEnded={onEnded}
            />
            <audio autoPlay={true} ref={audioRef}></audio>
          </LexiqueVideoWrapper>
        )}
      </Transition>
      <LexiqueControls
        backLabel={"back lookbook"}
        controlLabel={""}
        preorderLabel={""}
        disabled={isTransition}
        muted={muted}
        nextAction={nextSample}
        previousAction={previousSample}
        moreAction={goHome}
        muteAction={muteSound}
      />
    </Wrapper>
  )
}

export default LexiquePage

export const pageQuery = graphql`
  query LexiqueQuery {
    lexiques: allMarkdownRemark(
      sort: { fields: frontmatter___order }
      filter: { fileAbsolutePath: { regex: "/lexiques/" } }
    ) {
      nodes {
        frontmatter {
          items {
            title
            white
            space
            webm {
              publicURL
            }
            poster {
              publicURL
            }
            mp3 {
              publicURL
            }
            mp4 {
              publicURL
            }
          }
        }
      }
    }
  }
`
